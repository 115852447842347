<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>{{patientId == '' ? '添加' : '修改'}}<em>患者</em></span>
                    </div>
                    <!-- 患者列表 -->
                         <el-form :model="AddForm" ref="AddForm" label-width="100px" :rules="rules" style="width: 500px">
                            <el-form-item label="患者姓名" prop="name">
                                <el-input v-model="AddForm.name"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="sex">
                                <el-radio-group v-model="AddForm.sex">
                                    <el-radio :label="true">男</el-radio>
                                    <el-radio :label="false">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="年龄" prop="age">
                                <el-input v-model="AddForm.age" type="number"></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式" prop="phone">
                                <el-input v-model="AddForm.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号">
                                <el-input v-model="AddForm.idNo"></el-input>
                            </el-form-item>
                            <el-form-item label="地区" prop="regionId">
                                <el-cascader :placeholder="AddForm.region ? AddForm.region.fullName : '请选择所在区域'"
                                             v-model="AddForm.regionId"
                                             :options="RegionData"
                                             size="medium"
                                             :props="{  emitPath: false, multiple: false, checkStrictly: true,lazy: true,lazyLoad:LasyLoadRegion, value:'id',label:'regionName',children:'childrens' }"
                                             style="margin-right: 10px;width: 100%;">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="详细地址" prop="address">
                                <el-input v-model="AddForm.address"></el-input>
                            </el-form-item>
                            <el-form-item>
                                  <el-button type="primary" @click="onSubmit('AddForm')">立即创建</el-button>
                                    <el-button>取消</el-button>
                            </el-form-item>
                        </el-form>
                </div>
            </div>
     
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
    import ftCom from '../../components/footer'
    import nhCom from "../../components/navheader";
    import hdImg from "../../components/headerImg";
    import {patientMain} from "../../components/patienTmanagement/patienTmanagement";
    import { Region } from "../../components/region/Region";
    export default {
        name: "AddPatient",
        computed: {
            headers() {
                return {
                    "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem('Token')).access_token
                }
            }
        },
        components: {
            ftCom,
            nhCom,
            hdImg
        },
        data() {
            var region = new Region(this.TokenClient, this.Services.Authorization);
            var patient= new patientMain(this.TokenClient, this.Services.Authorization)
            return {
                patientDomain:patient,
                Region: region,
                AddForm:{
                    userId: this.$store.state.localData.userInfo.id,
                    PatientType: 1,
                    address: '',
                    age: '',
                    name: '',
                    phone: '',
                    regionId: null,
                    sex: true,
                    idNo: '',
                },
                rules:{
                    name:[
                        { required: true, message: '请输入患者姓名', trigger: 'blur' },
                    ],
                    age:[
                        { required: true, message: '请输入患者年龄', trigger: 'blur' },
                    ],
                    phone:[
                        { required: true, message: '请输入患者联系方式', trigger: 'blur' },
                        { pattern:/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                    ],
                    main:[
                        { required: true, message: '请输入主诉', trigger: 'blur' },
                    ],
                    diseaseDiagnose:[
                        { required: true, message: '请输入疾病诊断', trigger: 'blur' },
                    ],
                },
                errStr: "",
                RegionData: [],
                patientId: this.$route.query.patientId ? this.$route.query.patientId : ''
            };
        },
        mounted(){
           this.getDetail()
           this.InitialRegion()
        },
        methods: {
            InitialRegion() {
                var _this = this;
                _this.Region.Regions(null,
                    function (data) { _this.RegionData = data.data; },
                    function (err) { console.log(err); });
            },
            LasyLoadRegion(node, resolve) {
                var _this = this;
                _this.Region.Regions(node.value,
                    function (data) {
                        for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                            data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
                        }
                        resolve(data.data);
                    },
                    function (err) {
                        resolve([]);
                    });
            },
            getDetail(){
                if (this.patientId != '') {
                    this.patientDomain.GetPatientDetail(this.patientId, (data)=> {
                        this.AddForm = data.data
                    },function (error) {
                        // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                    })
                }
            },
            getList () {
                var _this = this;
                _this.userInfo = this.$store.state.localData.userInfo
                let params = this.patientType + '/' + _this.organitionId + '/' + _this.userInfo.id + '/ / /' + _this.keyWord + '/' + _this.pageIndex
                _this.patientDomain.getPatientList(params,function (data) {
                    console.log(data)
                    _this.patientList = data.data.results
                    	for (var i=0; i<_this.patientList.length; i++) {
							var f = _this.patientList[i].addTime.split('T')
							_this.patientList[i].addTime = f[0] + ' ' + f[1].slice(0,8)
						}

                },function (error) {
                    console.log(error)
                })
            },
             onSubmit(formName) {
                var _this = this
                    _this.$refs[formName].validate((valid) => {
                        if (valid) {
                            console.log(_this.AddForm)
                            if (_this.patientId != '') {
                                _this.AddForm.id = _this.patientId
                                _this.patientDomain.patientedit(_this.AddForm,
                                function (data) {
                                    _this.$alert('修改成功', '提示', {
                                        confirmButtonText: '确定并返回',
                                        type: 'success',
                                        callback: action => {
                                            _this.$router.go(-1)
                                        }
                                    });
                                },
                                function (error) {
                                    console.log(error);
                                });
                            } else {
                            _this.patientDomain.patientAdd(_this.AddForm,
                                function (data) {
                                    _this.$alert('添加成功', '提示', {
                                        confirmButtonText: '确定并返回',
                                        type: 'success',
                                        callback: action => {
                                            _this.$router.go(-1)
                                        }
                                    });
                                },
                                function (error) {
                                    console.log(error);
                                })
                            }


               
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
        }
    }
</script>

<style scoped>
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg") center no-repeat;
    }
    .patientheard{
        display: flex;
        align-items: center;
    }
    .patientBox {
        padding: 21px 42px;
        color: #808080
    }
    .patient {
        padding: 21px 0  15px 0;
        border-bottom: 1px dashed #CCCCCC;
    }
    .pList{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .pListLeft {
           display: flex;
        align-items: center;
    }
    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /**/
    .personal-content{
        background: #f7f7f7;
        padding: 40px 0;
    }
    .personal-box{
        width: 1200px;
        margin: 0 auto;
    }
    .personal-left{
        float: left;
    }
    .personal-right{
        float: right;
        width: 900px;
        background: #FFFFFF;
        box-sizing: border-box;
    }
    .personal-right-tit{
        border-bottom: 1px solid #e5e5e5;
        padding: 20px;
    }
    .personal-right-tit .iconfont{
        color: #04a290;
        font-size: 24px;
        margin-right: 5px;
    }
    .personal-right-tit span{
        display: inline-block;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
    }
    .personal-right-tit span em{
        font-style: normal;
        color: #04a290;
    }
    .personal-right>form{
        padding: 30px 15px;
        width: 460px;
    }
</style>
<style>
    .personal-center{
        display: none;
    }
</style>
